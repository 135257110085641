import React from 'react';

const Refresh = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="52.326"
    viewBox="0 0 56 52.326"
  >
    <g id="Picto_refresh" data-name="Picto refresh" transform="translate(-2411.3 132.844)">
      <path
        id="Tracé_1022"
        data-name="Tracé 1022"
        d="M118.425,39.2a26.163,26.163,0,1,0,25.4,32.191h0a1.826,1.826,0,1,0-3.555-.837A22.482,22.482,0,1,1,139.285,57l-4.126-2.111h0a1.825,1.825,0,1,0-1.654,3.251l7.757,3.955h0a1.826,1.826,0,0,0,2.415-.741l4.373-7.777h0a1.83,1.83,0,1,0-3.195-1.787l-2.167,3.879A26.139,26.139,0,0,0,118.426,39.2Z"
        transform="translate(2319 -172.047)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default Refresh;
